<template>
<div class="watch-video">
  <div class="watch-video__video-container">
    <video
      ref="video"
      :controls="playing"
      class="watch-video__video"
      :class="{ 'not-playing' : !playing }"
      preload="metadata"
      :poster="poster"
      v-if="sources.length"
    >
      <source v-for="({ src, type }) in sources" :key="type" :src="src" :type="`video/${type}`">
    </video>
    <div class="watch-video__meta" v-show="!playing">
      <button class="button watch-video__play" @click="playVideo">
        <div v-html="require(`!!html-loader!@/assets/svg/play.svg`)" class="play-icon" />
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AppVideo',
  props: {
    poster: {
      type: String,
      required: true,
    },
    sources: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
    };
  },
  methods: {
    playVideo() {
      this.playing = true;
      this.$refs.video.play();
    },
  }
};
</script>

<style lang="scss" scoped>
.watch-video {
  &__content {
    width: 100%;
    position: relative;
  }

  &__video-container {
    position: relative;
  }

  &__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: flex;

    &.not-playing {
      opacity: 0.7;
    }
  }

  &__meta {
    position: absolute;
    top: 45%;
    left: 50%;

    @media screen and (max-width: $medium - 1) {
      top: 36%;
      left: 40%;
    }

    .page-useful-article & {
      top: auto;
      bottom: 0;
      left: 0;
    }
  }

  &__play {
    background: #2e2e38;
    border-radius: 50%;
    color: #fff;
    height: auto;
    display: flex;
    align-items: center;
    padding: 20px 22px 20px 26px;
    transition: color 0.3s, background-color 0.3s;


    &:hover,
    &:focus {
      color: #2e2e38;
      background: #fff;

      & .play-icon {
        color: #000;
      }

      & .icon-circle {
        background: #000;
      }
    }

    .play-icon {
      width: 32px;
      height: 40px;
      color: #fff;
    }

    .icon-circle {
      border-radius: 50%;
      background: #fff;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .page-useful-article & {
      border-radius: 0;
      background-color: #0176D6;

      &:hover,
      &:focus {
        background-color: #004E86;
        & .play-icon {
          color: #F6F6F6;
        }
      }

      // medium
      @media screen and (min-width: $medium) and (max-width: $large - 1) {
        .play-icon {
          width: 24px;
          height: 28px;
        }
        padding: 18px 20px;
      }

      // small
      @media screen and (max-width: $medium - 1) {
        .play-icon {
          width: 19px;
          height: 24px;
        }
        padding: 16px 18px 16px 19px;
      }
    }
  }
}
</style>
