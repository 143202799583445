<template>
<div class="carousel" :class="{ light: light }">
  <div class="newgrid carousel-caption--container newgrid-small-col-reverse ">
    <div class="carousel-caption grid-block grid-block-1col grid-block-small-full grid-block-right-padding">
      <div class="carousel-caption--content">
        <div class="navigation-icon--group">
          <button @click="goToPrevious" class="navigation-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 24 0)">
                <circle cx="12" cy="12" r="12" fill="currentColor"/>
                <path :stroke="[light ? '#FFFFFF' : '#1A1A24']" stroke-linecap="round" stroke-width="3" d="M9.2416759 4.74167592l7.5166482 7.51664816M16.7583241 12.24167592l-7.5166482 7.51664816"/>
              </g>
            </svg>
          </button>
          <button @click="goToNext" class="navigation-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <circle cx="12" cy="12" r="12" fill="currentColor"/>
                <path :stroke="[light ? '#FFFFFF' : '#1A1A24']" stroke-linecap="round" stroke-width="3" d="M9.2416759 4.74167592l7.5166482 7.51664816M16.7583241 12.24167592l-7.5166482 7.51664816"/>
              </g>
            </svg>
          </button>
        </div>
        <span class="utopia-blk-headline carousel-caption__position">{{ currentIndex + 1 }}/{{ numImages }}</span>
        <h4 class="utopia-cap carousel-caption__title">{{ title }}</h4>
        <p class="slate-light carousel-caption__description">{{ description }}</p>
      </div>
    </div>
    <div class="grid-block grid-block-3col grid-block-medium-4col grid-block-small-full carousel-spacer-bounding-box">
      <div class="carousel-spacer">
        <carousel-3d
          ref="imageCarousel"
          inverseScaling="0"
          display="3"
          perspective="0"
          :space="cardWidth"
          :width="cardWidth"
          :height="cardHeight"
          :clickable="false"
          border="0"
          :loop="true"
          @after-slide-change="onAfterSlideChange"
        >
          <slide v-for="({ imagePath, alt, title, description }, idx) in carouselData" :key="idx" :index="idx">
            <template slot-scope="{ index, isCurrent }">
              <img
                :src="require(`@/assets/images/${imagePath}_lg.png`)"
                :srcset="buildSrcsetForImage(imagePath)"
                sizes="(max-width: 599px) 302px, (max-width: 899px) 456px, (max-width: 1439px) 489px, (min-width: 1440px) 820px"
                :alt="alt"
                :key="index"
                class="carousel-slide__img"
                :class="{ 'is-current': isCurrent }"
              />
            </template>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'ImageCarousel',
  props: {
    carouselData: {
      type: Array,
      required: true,
    },
    light: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
      cardWidth: 640,
    };
  },
  computed: {
    cardHeight() {
      // this is the aspect ratio of the slides. The number is the ratio of the
      // width against the height (1.87 times as big)
      return Math.round(this.cardWidth / 1.7787);
    },
    numImages() {
      return this.carouselData.length;
    },
    title() {
      return this.carouselData[this.currentIndex].title;
    },
    description() {
      return this.carouselData[this.currentIndex].description;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizing);
      this.resizing();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizing);
  },
  methods: {
    goToNext() {
      const index = this.currentIndex + 1;
      this.$refs.imageCarousel.goSlide(index)
    },
    goToPrevious() {
      const index = this.currentIndex === 0 ? this.numImages - 1 : this.currentIndex - 1;
      this.$refs.imageCarousel.goSlide(index)
    },
    onAfterSlideChange(newCurrentIndex) {
      this.currentIndex = newCurrentIndex;
    },
    buildSrcsetForImage(imagePath) {
      const sm = require(`@/assets/images/${imagePath}_sm.png`);
      const md = require(`@/assets/images/${imagePath}_md.png`);
      const lg = require(`@/assets/images/${imagePath}_lg.png`);
      const xl = require(`@/assets/images/${imagePath}_xl.png`);
      return `${sm} 302w, ${md} 456w, ${lg} 489w, ${xl} 820w`;
    },
    /*
      Called on Resize (see mount()), this looks for the first element with the class
      name '.carousel-spacer' and sets this.cardWidth to it. We use the spacer to
      set the width of the cards so this carousel can become fluid.

      Note that cardHeight is computed based on an aspect ratio that matches that
      of the images.
    */
    resizing(e) {
      const bounding = this.$el.querySelector('.carousel-spacer');
      if (!bounding) return;
      const r = bounding.getBoundingClientRect();
      this.cardWidth = Math.round(r.width);
    },
  },
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style lang="scss" scoped>

/*
  Assumes we're in a grid. carousel-spacer-bounding-box is given grid classes
  which are global. See styles/_grid.scss
  Carousel-spacer is used to programmatically determine the width of the slides.
  Change it here to
*/
.carousel-spacer {
  width: 100%;
  @media screen and (max-width: $medium - 1) {
    width: 100%;
  }

  &-bounding-box {
    overflow: visible;
  }
}

.carousel {

  /* puts the caption under the carousel slides */
  @media screen and (max-width: $medium - 1) {
    display: flex;
    flex-direction: column-reverse;
  }

  &-caption {
    z-index: 1;

    @media screen and (max-width: $medium - 1) {
      transform: translateY(0);
      padding-right: 0;
    }

    &--container {
      z-index: 1;
    }

    &--content {
      padding-top: 16px;
      border-top: 1px solid #fff;

      @media screen and (max-width: $medium - 1) {
        position: relative;
        margin-top: 16px;
      }

    }

    &__position {
      // xl
      @media screen and (min-width: $extra-large) {
        font-size: 18px;
        line-height: 24px;
      }
      // lg
      @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
        font-size: 14px;
        line-height: 21px;
      }

      // medium
      @media screen and (min-width: $medium) and (max-width: $large - 1) {
        font-size: 16px;
        line-height: 21px;
      }
    }

    &__title {
      @media screen and (min-width: $extra-large) {
        font-size: 14px;
        line-height: 24px;
      }
      // lg
      @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
        font-size: 13px;
        line-height: 21px;
      }
      // medium
      @media screen and (min-width: $medium) and (max-width: $large - 1) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__description {
      @media screen and (min-width: $extra-large) {
        font-size: 14px;
        line-height: 16px;
      }
      @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
        font-size: 13px;
        line-height: 18px;
      }
      // medium
      @media screen and (min-width: $medium) and (max-width: $large - 1) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .navigation-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      color: #fff;
      transition: all 0.3s;

      &:hover,
      &:active {
        color: $ey-yellow;
      }

      @media screen and (max-width: $medium - 1) {
        width: 32px;
        height: 32px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &--group {
        display: flex;
        justify-items: flex-start;
        margin-bottom: 16px;

        @media screen and (max-width: $medium - 1) {
          position: absolute;
          top: -80px;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}

.carousel {

  & .carousel-3d-slide {
    background-color: #000;

    & img {
      opacity: 0.25;

      &.is-current {
        opacity: 1;
      }
    }
  }



  .carousel-3d-container {
    overflow: visible;
    margin: 0;
  }

  /* Light version of the image carousel - used in L&G Useful case-study */
  &.light {
    & .carousel-3d-slide {
      background-color: #FFF;
    }

    .carousel-caption--content {
      top: 0;
      border-top: solid 1px #000;

      // small
      @media screen and (max-width: $medium - 1) {
        margin-top: 24px;
      }
    }

    .navigation-icon {
      color: #0176D6;
      &:hover,
      &:active {
        color: #002643;
      }
    }
  }
}
</style>
